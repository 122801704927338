import { lazy } from "react";
import {
  COLUMNS_EXERCISE,
  PICTURES_EXERCISE,
  FIRST_SOUND_FLUENCY_EXERCISE,
  LETTER_SOUND_FLUENCY_EXERCISE,
  LETTER_NAMING_FLUENCY_EXERCISE,
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE,
  LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE,
} from "./constants";

const ColumnsExercise = lazy(
  () => import("../components/Exercises/ColumnsExercise")
);
const PicturesExercise = lazy(
  () => import("../components/Exercises/PicturesExercise")
);

const FirstSoundFluencyExercise = lazy(
  () => import("../components/Exercises/FirstSoundFluencyExercise")
);

const LetterSoundFluencyExercise = lazy(
  () => import("../components/Exercises/LetterSoundFluencyExercise")
);
const LetterNamingFluencyExercise = lazy(
  () => import("../components/Exercises/LetterNamingFluencyExercise")
);
const LetterNamingFluencyCorrespondenceExercise = lazy(
  () =>
    import("../components/Exercises/LetterNamingFluencyCorrespondenceExercise")
);
const LetterSoundFluencyCompletionsExercise = lazy(
  () => import("../components/Exercises/LetterSoundFluencyCompletionsExercise")
);

const componentsMap: Record<string, React.LazyExoticComponent<any>> = {
  [COLUMNS_EXERCISE]: ColumnsExercise,
  [PICTURES_EXERCISE]: PicturesExercise,
  [FIRST_SOUND_FLUENCY_EXERCISE]: FirstSoundFluencyExercise,
  [LETTER_SOUND_FLUENCY_EXERCISE]: LetterSoundFluencyExercise,
  [LETTER_NAMING_FLUENCY_EXERCISE]: LetterNamingFluencyExercise,
  [LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE]:
    LetterNamingFluencyCorrespondenceExercise,
  [LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE]:
    LetterSoundFluencyCompletionsExercise,
};

export default componentsMap;
