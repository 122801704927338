import checkSvg from "../../assets/check.svg";

interface LettersAccuracyProps {
  lettersObject: LetterObject[];
}

interface LetterObject {
  text: string;
  correct: boolean;
}

function LettersAccuracy({ lettersObject }: LettersAccuracyProps) {
  return (
    <div className="text-white text-2xl">
      <h1 className="text-3xl mb-4 font-bold">Letter Accuracy</h1>
      <div className="w-[500px] flex flex-wrap justify-center items-center">
        {lettersObject.map((letter, index) => {
          return (
            <div
              className="flex"
              key={`letter-accuracy-${index}-${letter.text}`}
            >
              <div className="w-10 h-20 mr-1 mb-2 flex flex-col bg-purple-faded rounded-3xl p-2 flex items-center justify-between">
                <div className="">{letter.text?.toUpperCase()}</div>
                {letter.correct && (
                  <img className="w-[100px]" src={checkSvg} alt="check" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LettersAccuracy;
