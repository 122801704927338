import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import ScoreCardFlat from "../FullScore/components/ScoreCardFlat";
import Window from "../../components/Window";
import { calculateOverallPronunciationScore } from "../../utils/codeFromAzure";
import { cleanString } from "../../utils/string";
import Spinner from "../../components/Spinner";
import GoHomeButton from "../../components/GoHomeButton";
import { useLocation } from "react-router-dom";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import LettersAccuracy from "../../components/LettersAccuracy";
import { useSpeech } from "../../context/SpeechContext";

function NamingLetterCorrespondenceScore() {
  const location = useLocation();
  const resultsJson = location.state.resultsJson as any;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<any>([]);
  const { analyzedWordsWithErrors } = useSpeech();

  const { say } = useAvatar();
  const { allPhrasesCombined, durationInSec } = resultsJson;
  const { isMispronunciationError, isOmissionError, isInsertionError } =
    useSpeechErrors();

  useEffect(() => {
    say(
      "Congratulations, you completed the lesson! I knew you could do it! Click home and return to the main menu."
    );
    const result = calculateOverallPronunciationScore({
      allWords: structuredClone(analyzedWordsWithErrors.words ?? []),
      currentText: structuredClone(analyzedWordsWithErrors.words ?? []).map(
        (item: any) => item.Word
      ),
      reference_text: cleanString(allPhrasesCombined),
    });

    setResult(result);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [analyzedWordsWithErrors.words]);

  if (loading || !result) {
    return (
      <PageLayout className="items-center justify-center h-[100vh]">
        <Spinner className="!w-[100px] !h-[100px]" />
      </PageLayout>
    );
  }

  console.log("results", result);

  const noResults = result.length === 0;

  // const mispronunciations = result.filter((item: any) => {
  //   return isMispronunciationError(item);
  // });

  // const omissions = result.filter((item: any) => isOmissionError(item));
  // const resultsWithoutInsertions = result.filter(
  //   (item: any) => !isInsertionError(item)
  // );

  // // const totalOmissions =
  // //   omissions.length +
  // //   (allPhrasesCombined.split(" ").length - resultsWithoutInsertions.length);

  // // const insertions = result.filter((item: any) => isInsertionError(item));

  // // const errors = mispronunciations.length + totalOmissions + insertions.length;

  const correctWords = result.filter(
    (item: any) =>
      !isMispronunciationError(item) &&
      !isOmissionError(item) &&
      !isInsertionError(item)
  );

  const durationParts: any = !durationInSec
    ? []
    : [Math.trunc(durationInSec / 60), durationInSec % 60];
  if (durationParts.length === 2) {
    durationParts[1] =
      durationParts[1] < 10 ? `0${durationParts[1]}` : durationParts[1];
  }

  const letterClassification = allPhrasesCombined
    .split(" ")
    .map((letter: string) => ({
      text: letter,
      correct: correctWords.map((item: any) => item.Word).includes(letter),
    }));

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[50%] mb-8 pb-2 px-4 mt-4">
          <div className="min-h-[400px] w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
            <div className="text-5xl text-white font-bold mb-4">
              Lesson Completed!
            </div>
            <div className="flex justify-between ml-[50px]">
              <div className="w-full flex flex-col justify-center items-center">
                <div className="h-4" />

                <div className="mb-4 w-full flex justify-center items-center">
                  <div className="mt-4 w-60 mr-4">
                    <ScoreCardFlat
                      greenBg
                      label="Correct"
                      score={noResults ? 0 : correctWords.length}
                    />
                  </div>
                  <div className="mt-4 w-60">
                    <ScoreCardFlat
                      label="Incorrect"
                      withBorder
                      score={
                        allPhrasesCombined.split(" ").length -
                        correctWords.length
                      }
                    />
                  </div>
                </div>
                <div className="mb-4 w-full flex justify-center items-center">
                  <div className="mt-4 w-60 mr-4">
                    <ScoreCardFlat
                      label="Time"
                      score={durationParts.join(":")}
                    />
                  </div>
                </div>
                <LettersAccuracy lettersObject={letterClassification} />
              </div>
              <div className="w-16" />
            </div>
          </div>
          <div className="relative flex justify-center items-center mb-8">
            <GoHomeButton />
          </div>
        </Window>

        <div className=" mb-4">
          <RichAvatar message={resultsJson.avatarFinalMessage} />
        </div>
      </PageLayout>
    </div>
  );
}

export default NamingLetterCorrespondenceScore;
