import { useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import ondulationBg from "../../assets/ondulationBg.svg";
import cubesTopLeft from "../../assets/cubes_top_left.png";
import Window from "../../components/Window";
import { LocaleSelector } from "../LocaleSelector/LocaleSelector";
import ConfigPanel from "../../components/ConfigPanel";

function Home() {
  const navigate = useNavigate();
  const queryString = window.location.search; // Get the current query string
  const searchParams = new URLSearchParams(queryString);
  const showConfigPanel = searchParams.get("config") === "true";

  return (
    <PageLayout className="justify-center relative">
      <LocaleSelector />
      {showConfigPanel && <ConfigPanel />}
      <div className="flex justify-center items-center flex-col bg-purple-gradient w-full h-[100vh]">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <Window className="min-w-[50%] min-h-1/2 justify-center items-center">
          <h1 className="text-5xl text-white leading-[60px] font-bold text-center mb-4">
            Welcome To
            <br />
            Fluency Coach!
          </h1>
          <div className="flex flex-row gap-4">
            <div className="flex flex-col items-center">
              {/* <button
                onClick={() => {
                  navigate(`/lesson${queryString}`);
                }}
                className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                Lesson
              </button>

              <button
                onClick={() => {
                  navigate(`/assessment${queryString}`);
                }}
                className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                Assessment
              </button>

              <button
                onClick={() => {
                  navigate(`/mastery/default${queryString}`);
                }}
                className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                Mastery
              </button> */}
            </div>
            <button
              style={{
                width: "252px",
              }}
              onClick={() => {
                navigate(`/mastery/naming-letters${queryString}`);
              }}
              className=" mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Letter
              <br />
              Naming
            </button>
            <button
              style={{
                width: "252px",
              }}
              onClick={() => {
                navigate(`/mastery/letter-sound-fluency${queryString}`);
              }}
              className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Letter <br /> Sound
            </button>
            {/* <button
              style={{
                width: "252px",
              }}
              onClick={() => {
                navigate(
                  `/mastery/letter-sound-completions-fluency${queryString}`
                );
              }}
              className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Letter <br /> Sound
              <br />
              Completions
            </button> */}
            <button
              style={{
                width: "252px",
              }}
              onClick={() => {
                navigate(
                  `/mastery/naming-letters-correspondence${queryString}`
                );
              }}
              className="min-h-[96px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Letter <br />
              Correspondence
            </button>
            {/* <button
                onClick={() => {
                  navigate(`/mastery/first-sound-fluency${queryString}`);
                }}
                className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                First Sound Fluency
              </button> */}

            {/* <button
                onClick={() => {
                  navigate(
                    `/mastery/letter-sound-fluency-alphabet${queryString}`
                  );
                }}
                className="w-[252px] mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                Letter Sound Fluency <br />
                Alphabet
              </button> */}
          </div>
        </Window>
      </div>
      <img src={ondulationBg} className="absolute bottom-0 left-0" />
    </PageLayout>
  );
}

export default Home;
