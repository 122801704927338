import {
  LETTER_NAMING_FLUENCY_EXERCISE,
  LETTER_NAMING_FLUENCY_ACTIVITY,
} from "../utils/constants";

type ExerciseType =
  | "COLUMNS_EXERCISE"
  | "PICTURES_EXERCISE"
  | "LETTER_SOUND_FLUENCY_EXERCISE"
  | "LETTER_NAMING_FLUENCY_EXERCISE";
type ActivityType =
  | "REPEAT_AFTER_TEACHER_ACTIVITY"
  | "POINT_AND_SAY_ACTIVITY"
  | "SPELL_WORD_ACTIVITY"
  | "ANSWER_THE_QUESTION_ACTIVITY"
  | "TILT_HEAD_ACTIVITY"
  | "DETECT_PHONEMES"
  | "LETTER_SOUND_FLUENCY_ACTIVITY"
  | "LETTER_NAMING_FLUENCY_ACTIVITY";

interface Activity {
  id: number;
  type: ActivityType;
  props: Record<string, any>;
}

interface Exercise {
  id: number;
  type: ExerciseType;
  props: Record<string, any>;
  activities: Activity[];
}

interface Lesson {
  id: number;
  current: {
    exercise: number;
    activity: number;
  };
  exercises: Exercise[];
}

const data: Lesson = {
  id: 281829,
  current: {
    exercise: 0,
    activity: 0,
  },
  exercises: [
    {
      id: 781231827,
      type: LETTER_NAMING_FLUENCY_EXERCISE,
      props: {
        title: "Letter Naming Fluency",
        completeReferenceText:
          "o s g o t f j q w y p t b l o y w h q p r t m p e s m t h n x s d k q f a c b s a p g z l r w o i p",
      },
      activities: [
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_ACTIVITY,
          props: {
            letters: ["o", "s", "g", "o", "t", "f", "j", "q", "w", "y"],
          },
        },
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_ACTIVITY,
          props: {
            letters: ["p", "t", "b", "l", "o", "y", "w", "h", "q", "p"],
          },
        },
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_ACTIVITY,
          props: {
            letters: ["r", "t", "m", "p", "e", "s", "m", "t", "h", "n"],
          },
        },
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_ACTIVITY,
          props: {
            letters: ["x", "s", "d", "k", "q", "f", "a", "c", "b", "s"],
          },
        },
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_ACTIVITY,
          props: {
            letters: ["a", "p", "g", "z", "l", "r", "w", "o", "i", "p"],
          },
        },
      ],
    },
  ],
};

export default data;
