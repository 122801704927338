import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import ScoreCardFlat from "../FullScore/components/ScoreCardFlat";
import ScoreCard from "../FullScore/components/ScoreCard";
import Window from "../../components/Window";
import PageControl from "./components/PageControl";
import GoHomeButton from "../../components/GoHomeButton";
import { useLocation } from "react-router-dom";

function FirstSoundFluencyScore() {
  const location = useLocation();
  const resultsJson = location.state.resultsJson as any;

  const totalPages = 2;
  const [scorePageStep, setScorePageStep] = useState(0);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  const avatarMessage =
    "Congratulations, you completed the lesson! I knew you could do it! Click home and return to the main menu.";

  useEffect(() => {
    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;

      setTimeout(() => {
        say(avatarMessage);
      }, 300);
    }
  }, [isConnected]);

  const onNextPage = () => {
    if (scorePageStep + 1 > totalPages - 1) return;
    setScorePageStep(scorePageStep + 1);
  };

  const onPreviousPage = () => {
    if (scorePageStep - 1 < 0) return;
    setScorePageStep(scorePageStep - 1);
  };

  const onClickNode = (index: number) => {
    setScorePageStep(index);
  };

  function getOverallScore(attempts: any) {
    let totalAttempts = 0;
    let correctAttempts = 0;

    for (const phoneme in attempts) {
      const phonemeAttempts = attempts[phoneme];

      totalAttempts += phonemeAttempts.length;
      correctAttempts += phonemeAttempts.filter(
        (attempt: any) => attempt.correct
      ).length;
    }

    if (totalAttempts === 0) {
      return 0;
    }

    const score = (correctAttempts / totalAttempts) * 100;

    return Math.round(score);
  }

  function getAttemptCounts(attempts: any) {
    let correctCount = 0;
    let errorCount = 0;

    for (const phoneme in attempts) {
      const phonemeAttempts = attempts[phoneme];

      phonemeAttempts.forEach((attempt: any) => {
        if (attempt.correct) {
          correctCount++;
        } else {
          errorCount++;
        }
      });
    }

    return {
      correctCount,
      errorCount,
    };
  }

  const overallScore = getOverallScore(resultsJson.attempts);
  const { errorCount } = getAttemptCounts(resultsJson.attempts);

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[50%] mb-8 pb-2 px-4 mt-4">
          {scorePageStep === 0 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <ScoreCard
                      label="Overall Score"
                      score={Math.floor(overallScore)}
                    />
                    <div className="grid grid-cols-1 gap-2 mt-4">
                      <ScoreCardFlat
                        label="Failed attempts"
                        score={Math.floor(errorCount)}
                      />
                    </div>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
          {scorePageStep === 1 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <h1 className="text-[40px] font-bold text-white mb-8">
                      Details:
                      {Object.keys(resultsJson.attempts).map(
                        (key: any, keyIndex: number) => {
                          const item = resultsJson.attempts[key];

                          return item.map((subItem: any, index: number) => {
                            return (
                              <div
                                key={`explanation-${keyIndex}-${index}`}
                                className="grid gap-2 mt-8"
                              >
                                <ScoreCardFlat
                                  score={`Attempted: ${key.toUpperCase()}`}
                                  label={`Detected: ${subItem.ai_answer} (${
                                    subItem.correct ? "Correct" : "Incorrect"
                                  })`}
                                />
                              </div>
                            );
                          });
                        }
                      )}
                    </h1>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
        </Window>

        <div className="mt-8 mb-4">
          <RichAvatar message={avatarMessage} />
        </div>
      </PageLayout>
    </div>
  );
}

export default FirstSoundFluencyScore;
