import openMicPng from "../../assets/openMic.png";
import closedMicPng from "../../assets/closedMic.png";
interface TopBarProps {
  title: string;
  state?: string;
}

function TopBar({ title, state }: TopBarProps) {
  return (
    <div className="flex items-center justify-between border-[#3800D7] bg-purple-gradient relative pb-4 rounded-xl -z-2 pt-2 px-2 mb-2 border-b-[12px] rounded-b-[24px] border-2">
      <div className="border-1 border border-[#b98dfd] p-2 rounded-xl ml-4 bg-purple-light text-white font-semibold text-lg mt-2">
        <span>{title}</span>
      </div>
      <div className="flex items-center justify-center p-2">
        {state && (
          <div className="flex items-center gap-2 mr-2">
            <span className="text-white font-semibold text-lg block mt-2">
              {state}
            </span>
          </div>
        )}
        <div className="w-[48px] h-[48px] right-0 top-0">
          <img src={state ? closedMicPng : openMicPng} />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
