import { Suspense } from "react";
import Spinner from "../Spinner";

function DynamicComponentLoader({
  ComponentToRender,
  componentKey,
  componentProps,
  ...props
}: any) {
  if (!ComponentToRender) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <ComponentToRender {...props} key={componentKey} {...componentProps} />
    </Suspense>
  );
}

export default DynamicComponentLoader;
