import infoSvg from "../../../assets/info.svg";
import Tooltip from "../../../components/Tooltip";

const ScoreCardFlat: React.FC<{
  label: string;
  score: number | string;
  withBorder?: boolean;
  infoText?: string;
  className?: string;
  greenBg?: boolean;
}> = ({ label, score, infoText, className, greenBg }) => {
  return (
    <div
      className={`
        ${
          greenBg
            ? "bg-green-gradient border-dark-green"
            : "border-[#3800D7] bg-purple-gradient"
        }
        relative pb-4 rounded-xl -z-2 pt-2 px-2 mb-2  border-b-[12px] rounded-b-[24px] border-2
        ${className ? className : ""}`}
    >
      <div className="text-white text-4xl font-semibold">{score}</div>
      <div>
        <span className="text-white font-semibold text-lg block mt-2">
          {label}
        </span>
        {infoText && (
          <div className="z-10 absolute top-5 right-3 cursor-pointer">
            <Tooltip label={infoText}>
              <img src={infoSvg} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreCardFlat;
