import { UserButton } from "@clerk/clerk-react";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const AVATAR_ID = import.meta.env.VITE_AVATAR_ID;

function PageLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const [isConnecting, setIsConnecting] = useState(false);
  const location = useLocation();

  const { connect, isConnected } = useAvatar();
  function catchUserInteraction() {
    if (isConnecting || isConnected) return;

    setIsConnecting(true);
    connect(Number(AVATAR_ID ?? 15))
      .catch(console.error)
      .finally(() => {
        setIsConnecting(false);
      });
  }

  return (
    <div
      className={`text-black min-h-[100vh] min-w-[100vw] flex flex-col items-center ${className}`}
      onClick={catchUserInteraction}
    >
      <div
        className={`flex justify-end items-center p-4 absolute top-0 right-0 ${
          location.pathname === "/"
            ? "top-8 right-[280px] z-20"
            : "top-0 right-0"
        }`}
      >
        <UserButton
          showName
          appearance={{
            elements: {
              userButtonOuterIdentifier: {
                color: location.pathname === "/" ? "white" : "black",
              },
            },
          }}
        />
      </div>
      {children}
    </div>
  );
}

export default PageLayout;
