export enum Emotions {
  HAPPY = "HAPPY",
  NEUTRAL = "NEUTRAL",
  SAD = "SAD",
  ANGRY = "ANGRY",
  FEARFUL = "FEARFUL",
  DISGUSTED = "DISGUSTED",
  SURPRISED = "SURPRISED",
}

export enum HeadPoses {
  TILT_RIGHT = "TILT_RIGHT",
  TILT_LEFT = "TILT_LEFT",
  NEUTRAL = "NEUTRAL",
}

export enum HandPoses {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  POINT = "POINT",
  OVER_THE_HEAD = "OVER_THE_HEAD",
  NONE = "NONE",
}
