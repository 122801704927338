import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import ScoreCardFlat from "../FullScore/components/ScoreCardFlat";
import ScoreCard from "../FullScore/components/ScoreCard";
import Window from "../../components/Window";
import PageControl from "./components/PageControl";
import GoHomeButton from "../../components/GoHomeButton";
import { useLocation } from "react-router-dom";

function LetterSoundFluencyScore() {
  const location = useLocation();
  const resultsJson = location.state.resultsJson as any;
  console.log("resultsJson on score page!", resultsJson);

  const totalPages = 2;
  const [scorePageStep, setScorePageStep] = useState(0);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  const avatarMessage =
    "Congratulations, you completed the lesson! I knew you could do it! Click home and return to the main menu.";

  useEffect(() => {
    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;

      setTimeout(() => {
        say(avatarMessage);
      }, 300);
    }
  }, [isConnected]);

  const onNextPage = () => {
    if (scorePageStep + 1 > totalPages - 1) return;
    setScorePageStep(scorePageStep + 1);
  };

  const onPreviousPage = () => {
    if (scorePageStep - 1 < 0) return;
    setScorePageStep(scorePageStep - 1);
  };

  const onClickNode = (index: number) => {
    setScorePageStep(index);
  };

  function getOverallScore() {
    if (!resultsJson) return 0;
    const correctCount = resultsJson.correctLetterRef.length;
    const incorrectCount = resultsJson.incorrectLetterRef.length;
    const total = correctCount + incorrectCount;

    // Calculate overall score as a percentage
    if (total === 0) return 0; // Prevent division by zero
    const score = (correctCount / total) * 100;

    return Math.round(score); // Return score rounded to nearest integer
  }

  const overallScore = getOverallScore();
  const errorsCount = resultsJson.incorrectLetterRef.length;
  const correctCount = resultsJson.correctLetterRef.length;

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[50%] mb-8 pb-2 px-4 mt-4">
          {scorePageStep === 0 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <ScoreCard
                      label="Overall Score"
                      score={Math.floor(overallScore)}
                    />
                    <div className="grid grid-cols-2 gap-2 mt-4">
                      <ScoreCardFlat
                        label="Letters sounded correctly"
                        score={Math.floor(correctCount)}
                      />
                      <ScoreCardFlat
                        label="Letters sounded incorrectly"
                        score={Math.floor(errorsCount)}
                      />
                    </div>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
          {scorePageStep === 1 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <h1 className="text-[40px] font-bold text-white mb-8">
                      Details:
                      {resultsJson.incorrectLetterRef.map(
                        (item: any, index: number) => (
                          <div
                            key={`incorrect-attempt-${index}`}
                            className="grid gap-2 mt-8"
                          >
                            <ScoreCardFlat
                              score={`Attempted: ${item.phoneme.toUpperCase()}`}
                              label={`Detected: ${item.ai_answer} (Incorrect)`}
                            />
                          </div>
                        )
                      )}
                      {resultsJson.correctLetterRef.map(
                        (item: any, index: number) => (
                          <div
                            key={`correct-attempt-${index}`}
                            className="grid gap-2 mt-8"
                          >
                            <ScoreCardFlat
                              score={`Attempted: ${item.phoneme.toUpperCase()}`}
                              label={`Detected: ${item.ai_answer} (Correct)`}
                            />
                          </div>
                        )
                      )}
                    </h1>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
        </Window>

        <div className="mt-8 mb-4">
          <RichAvatar message={avatarMessage} />
        </div>
      </PageLayout>
    </div>
  );
}

export default LetterSoundFluencyScore;
