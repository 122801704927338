import homeIconSvg from "../../assets/homeIcon.svg";

function GoHomeButton({ noTitle }: { noTitle?: boolean }) {
  const queryString = location.search;
  const goHome = () => {
    window.location.href = `/${queryString}`;
  };

  return (
    <div
      style={{
        width: noTitle ? "54px" : "160px",
        height: noTitle ? "54px" : "64px",
      }}
      className={`
        ${noTitle ? "" : "mt-4 w-160 h-64 px-8 py-4 z-[99999]"}
          border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl flex justify-center items-center cursor-pointer
        `}
      onClick={goHome}
    >
      <img
        style={{
          marginRight: noTitle ? "0" : "8px",
        }}
        src={homeIconSvg}
      />
      <div>{noTitle ? "" : "Home"}</div>
    </div>
  );
}

export default GoHomeButton;
