import {
  LETTER_SOUND_FLUENCY_EXERCISE,
  LETTER_SOUND_FLUENCY_ACTIVITY,
} from "../utils/constants";

type ExerciseType =
  | "COLUMNS_EXERCISE"
  | "PICTURES_EXERCISE"
  | "LETTER_SOUND_FLUENCY_EXERCISE";
type ActivityType =
  | "REPEAT_AFTER_TEACHER_ACTIVITY"
  | "POINT_AND_SAY_ACTIVITY"
  | "SPELL_WORD_ACTIVITY"
  | "ANSWER_THE_QUESTION_ACTIVITY"
  | "TILT_HEAD_ACTIVITY"
  | "DETECT_PHONEMES"
  | "LETTER_SOUND_FLUENCY_ACTIVITY";

interface Activity {
  id: number;
  type: ActivityType;
  props: Record<string, any>;
}

interface Exercise {
  id: number;
  type: ExerciseType;
  props: Record<string, any>;
  activities: Activity[];
}

interface Lesson {
  id: number;
  current: {
    exercise: number;
    activity: number;
  };
  exercises: Exercise[];
}

const data: Lesson = {
  id: 281829,
  current: {
    exercise: 0,
    activity: 0,
  },
  exercises: [
    {
      id: 781231827,
      type: LETTER_SOUND_FLUENCY_EXERCISE,
      props: {
        title: "Letter Sound Fluency",
        avatarInstructions: `Let's sound out letters! Click the button and let's start!`,
      },
      activities: [
        {
          id: 8123541,
          type: LETTER_SOUND_FLUENCY_ACTIVITY,
          props: {
            phonemes: ["A", "E", "D", "V", "L", "M", "S", "F"],
          },
        },
      ],
    },
  ],
};

export default data;
