import React, { useEffect, useRef, ReactNode, useState } from "react";
import { RichAvatar } from "../Avatar/RichAvatar";
import * as handpose from "@tensorflow-models/handpose";
import * as faceapi from "@vladmandic/face-api";
import { Emotions, HandPoses, HeadPoses } from "../../types/gestures";
import { useGestureDetection } from "../../context/GestureDetectionContext";
import { useMasteryLesson } from "../../context/MasteryLessonContext";

interface CameraWrapperProps {
  children: ReactNode;
  width?: number;
  height?: number;
  left?: number;
  bottom?: number;
  top?: number;
  right?: number;
}

const CameraWrapper: React.FC<CameraWrapperProps> = ({
  children,
  left,
  bottom,
  top,
  right,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { setEmotion, setHeadPose, setHandPose, detectionActive } =
    useGestureDetection();

  const [detectionEnabled, setDetectionEnabled] = useState(false);
  const [handposeModel, setHandposeModel] = useState<handpose.HandPose | null>(
    null
  );
  const { avatarState } = useMasteryLesson();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL =
        "https://cdn.jsdelivr.net/npm/@vladmandic/face-api/model/";
      await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
      await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
      await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
    };

    const initializeHandpose = async () => {
      const model = await handpose.load();
      setHandposeModel(model);
    };

    const getCameraFeed = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play();
            setDetectionEnabled(true); // Start detection after video is ready
          };
        }

        await loadModels();
        initializeHandpose();
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    };

    getCameraFeed();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        (videoRef.current.srcObject as MediaStream)
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    let animationFrameId: number | null = null;

    const detect = async () => {
      if (
        !detectionActive ||
        !detectionEnabled ||
        !videoRef.current ||
        !canvasRef.current ||
        !handposeModel
      ) {
        return; // Exit if detection is not active or video is not ready
      }

      const video = videoRef.current;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d")!;

      // Ensure video has valid dimensions
      if (video.videoWidth === 0 || video.videoHeight === 0) {
        console.warn("Video has invalid dimensions, skipping detection.");
        return;
      }

      // Clear canvas before drawing
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Run face detection
      const faceDetections = await faceapi
        .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions();

      let headTopY = 0;
      if (faceDetections && faceDetections.landmarks) {
        const landmarks = faceDetections.landmarks;
        // @ts-ignore
        const { _positions: positions } = landmarks;

        positions.forEach(({ x, y }) => {
          ctx.beginPath();
          ctx.arc(x, y, 2, 0, 2 * Math.PI);
          ctx.fillStyle = "blue";
          ctx.fill();
        });

        const leftEye = landmarks.getLeftEye();
        const rightEye = landmarks.getRightEye();

        const leftEyeY = leftEye[0].y;
        const rightEyeY = rightEye[3].y;

        const eyeTiltAngle =
          Math.atan2(rightEyeY - leftEyeY, rightEye[3].x - leftEye[0].x) *
          (180 / Math.PI);

        if (Math.abs(eyeTiltAngle) > 10) {
          const headPos =
            eyeTiltAngle > 0 ? HeadPoses.TILT_RIGHT : HeadPoses.TILT_LEFT;
          setHeadPose(headPos);
        } else {
          setHeadPose(HeadPoses.NEUTRAL);
        }

        headTopY = Math.min(...positions.map((p) => p.y));

        const expressions: any = faceDetections.expressions;
        const emotion = Object.keys(expressions).reduce((a, b) =>
          expressions[a] > expressions[b] ? a : b
        );

        setEmotion(emotion.toUpperCase() as Emotions);
      }

      // Run hand detection
      const predictions = await handposeModel.estimateHands(video);

      if (predictions.length > 0) {
        predictions.forEach((prediction) => {
          const wrist = prediction.landmarks[0];

          if (headTopY && wrist[1] < headTopY) {
            setHandPose(HandPoses.OVER_THE_HEAD);
          } else {
            setHandPose(HandPoses.NONE);
          }

          prediction.landmarks.forEach((landmark) => {
            const [x, y] = landmark;
            ctx.beginPath();
            ctx.arc(x, y, 5, 0, 2 * Math.PI);
            ctx.fillStyle = "red";
            ctx.fill();
          });
        });
      }

      // Continue the detection loop
      animationFrameId = requestAnimationFrame(detect);
    };

    if (detectionActive && detectionEnabled) {
      detect();
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [detectionActive, detectionEnabled, handposeModel]);

  return (
    <>
      <div
        style={{
          zIndex: 999,
          top: top ? `${top}px` : "auto",
          right: right ? `${right}px` : "auto",
          bottom: bottom ? `${bottom}px` : "25px",
          left: left ? `${left}px` : "25px",
        }}
        className="absolute flex  overflow-hidden"
      >
        {/* Only canvas for landmarks */}
        <div className="w-[160px] h-[172px] relative justify-center items-center">
          <video
            ref={videoRef}
            autoPlay
            muted
            className="border-semi-gray border border-2 bg-gray-gradient border-b-[12px] rounded-[21px] w-full h-full object-cover"
          />
          <canvas
            ref={canvasRef}
            className="absolute top-0 left-0 w-full h-full pointer-events-none"
          />
        </div>

        {/* RichAvatar next to the camera feed */}
        <div
          style={{ zIndex: 999999999 }}
          className="relative ml-2 rounded-[28px] border-semi-gray border border-2 border-b-[12px]"
        >
          <RichAvatar
            avatarState={avatarState}
            size="160"
            message="Hello"
            onCamera
          />
        </div>
      </div>

      {/* Render children inside the wrapper */}
      <div>{children}</div>
    </>
  );
};

export default CameraWrapper;
