import {
  COLUMNS_EXERCISE,
  PICTURES_EXERCISE,
  REPEAT_AFTER_TEACHER_ACTIVITY,
  TILT_HEAD_ACTIVITY,
} from "../utils/constants";

type ExerciseType =
  | "COLUMNS_EXERCISE"
  | "PICTURES_EXERCISE"
  | "PHONEMES_EXERCISE";
type ActivityType =
  | "REPEAT_AFTER_TEACHER_ACTIVITY"
  | "POINT_AND_SAY_ACTIVITY"
  | "SPELL_WORD_ACTIVITY"
  | "ANSWER_THE_QUESTION_ACTIVITY"
  | "TILT_HEAD_ACTIVITY"
  | "DETECT_PHONEMES";

interface Activity {
  id: number;
  type: ActivityType;
  props: Record<string, any>;
}

interface Exercise {
  id: number;
  type: ExerciseType;
  props: Record<string, any>;
  activities: Activity[];
}

interface Lesson {
  id: number;
  current: {
    exercise: number;
    activity: number;
  };
  exercises: Exercise[];
}

const data: Lesson = {
  id: 281829,
  current: {
    exercise: 0,
    activity: 0,
  },
  exercises: [
    {
      id: 19191,
      type: COLUMNS_EXERCISE,
      props: {
        columns: [
          [
            "Mr. Daniels",
            "recognize",
            "elevator",
            "medicine",
            "guess",
            "dozen",
          ],
          ["silently", "watering", "heater", "approached", "fairly"],
          ["clues", "doctors", "offices", "drugs", "typists", "lawyers"],
          [
            "cock your head",
            "equipment",
            "motorcycle",
            "refinery",
            "friendly",
            "unfriendly",
          ],
          ["explain", "insist", "honest", "crude", "fifth"],
          ["narrow", "prison", "polite", "several", "pipeline"],
        ],
      },
      activities: [
        {
          id: 821123,
          type: TILT_HEAD_ACTIVITY,
          props: {
            word: "cock your head",
          },
        },
      ],
    },
    {
      id: 19292,
      type: PICTURES_EXERCISE,
      props: {},
      activities: [
        { id: 1852, type: REPEAT_AFTER_TEACHER_ACTIVITY, props: {} },
      ],
    },
  ],
};

export default data;
