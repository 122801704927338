import exerciseComponentsMap from "../../utils/exerciseMap";
import DynamicComponentLoader from "../../components/DynamicComponentLoader";
import PageLayout from "../../components/PageLayout";
import { useMasteryLesson } from "../../context/MasteryLessonContext";
import CameraWrapper from "../../components/CameraWrapper/CameraWrapper";
import ondulationBgPurple from "../../assets/ondulationBgPurple.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import Window from "../../components/Window";
import GoHomeButton from "../../components/GoHomeButton";
import { GestureDetectionProvider } from "../../context/GestureDetectionContext";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import bubbleSvg from "../../assets/bubble.svg";
import TopBar from "../../components/TopBar/TopBar";

function MasteryLesson({
  data,
  withCamera,
}: {
  data: any;
  withCamera?: boolean;
}) {
  const { exerciseType, currentExercise, avatarState } = useMasteryLesson();
  if (!exerciseType) return;
  const exercise = data.exercises[currentExercise];

  return (
    <>
      <div className="z-10 flex justify-center items-center w-full absolute top-0 ">
        {!withCamera && (
          <div className="w-[690px]">
            <TopBar title={exercise?.props?.title} state={avatarState} />
          </div>
        )}
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: "#6d09fc",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
          backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgPurple})`,
        }}
      >
        <PageLayout className="pt-32 relative">
          {/* Load Current Exercise */}
          <div className="relative h-full pb-[130px]">
            {withCamera ? (
              <GestureDetectionProvider>
                <CameraWrapper bottom={0}>
                  <div className="absolute top-[15px] left-[-65px]">
                    <GoHomeButton noTitle />
                  </div>
                  <Window
                    noPadding
                    className="relative min-h-[40vh] p-8 pb-20 bg-white-gradient border-semi-white min-w-[690px] h-[80%] justify-center items-center"
                  >
                    <DynamicComponentLoader
                      key={`exercise-${currentExercise}`}
                      componentProps={exercise.props}
                      ComponentToRender={exerciseComponentsMap[exerciseType]}
                    />
                  </Window>
                </CameraWrapper>
              </GestureDetectionProvider>
            ) : (
              <div>
                <Window
                  noPadding
                  className="min-h-[40vh] p-8 pb-20 bg-white-gradient border-semi-white min-w-[690px] h-[80%] justify-center items-center"
                >
                  <DynamicComponentLoader
                    key={`exercise-${currentExercise}`}
                    componentProps={exercise.props}
                    ComponentToRender={exerciseComponentsMap[exerciseType]}
                  />
                </Window>
                <div className="absolute bottom-[-200px] left-0 w-[300px] h-[300px] flex justify-center items-center">
                  <div className="rounded-full absolute z-[1] left-[-12px] top-[-12px] border-[#3800D7] bg-purple-gradient relative border-b-[8px]  border-2">
                    <RichAvatar
                      extraStyle={{ zIndex: 1 }}
                      size="160"
                      message="Hello"
                    />
                  </div>
                  <img
                    src={bubbleSvg}
                    alt="bubble"
                    className="absolute top-[-10px] left-[-10px] z-[0] w-[350px] h-[300px]"
                  />
                </div>
              </div>
            )}
          </div>
        </PageLayout>
      </div>
    </>
  );
}

export default MasteryLesson;
