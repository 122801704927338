import { Navigate, Route, Routes } from "react-router-dom";
import "./styles.css";
import Home from "./pages/Home";
import Lesson from "./pages/Lesson";
import { AudioProvider } from "./context/AudioRecordingContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import SignIn from "./pages/Auth/SignIn";
import { ReactNode } from "react";
import { ModalProvider } from "./context/ModalContext";
import { InterventionsProvider } from "./context/InterventionsContext";
import { SpeechProvider } from "./context/SpeechContext";
import Score from "./pages/Score";
import { LessonProvider } from "./context/LessonContext";
import { MasteryLessonProvider } from "./context/MasteryLessonContext";
import { AvatarClient, AvatarProvider } from "alpha-ai-avatar-sdk-react";
import Assessment from "./components/Assessment";
import FullScorePage from "./pages/FullScore/FullScore";
import { AppConfigProvider } from "./context/AppConfig";
import SignUp from "./pages/Auth/SignUp";
import MasteryLesson from "./pages/MasteryLesson";
import NamingLetterScore from "./pages/NamingLetterScore/NamingLetterScore";
import LetterSoundFluencyScore from "./pages/LetterSoundFluencyScore";
import masteryLessonMockLetterSoundFluency from "./mock/masteryLessonMockLetterSoundFluency";
import masteryLessonMockDefault from "./mock/masteryLessonMock";
import masteryLessonMockFirstSoundFluency from "./mock/masteryLessonMockFirstSoundFluency";
import masteryLessonMockLetterSoundFluencyAlphabet from "./mock/masteryLessonMockLetterSoundFluencyAlphabet";
import masteryLessonMockLetterNamingFluency from "./mock/masteryLessonMockLetterNamingFluency";
import FirstSoundFluencyScore from "./pages/FirstSoundFluencyScore";
import masteryLessonMockLetterNamingCorrespondenceFluency from "./mock/masteryLessonMockLetterNamingCorrespondenceFluency";
import NamingLetterCorrespondenceScore from "./pages/NamingLetterCorrespondenceScore";
import masteryLessonMockLetterSoundCompletionsFluency from "./mock/masteryLessonMockLetterSoundCompletionsFluency";

const AVATAR_KEY = import.meta.env.VITE_AVATAR_KEY;
const BASE_URL = import.meta.env.VITE_AVATAR_BASE_URL;

const client = new AvatarClient({ apiKey: AVATAR_KEY, baseUrl: BASE_URL });

const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryString = window.location.search;
  const { userId } = useAuth();

  if (!userId) {
    return <Navigate to={`/sign-in${queryString}`} replace />;
  }

  return children;
};

function App() {
  return (
    <AppConfigProvider>
      <AuthProvider>
        <LessonProvider>
          <AvatarProvider client={client}>
            <SpeechProvider>
              <InterventionsProvider>
                <ModalProvider>
                  <AudioProvider>
                    {" "}
                    <Routes>
                      <Route path="/sign-in" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUp />} />
                      <Route
                        path="/"
                        element={
                          <ProtectedRoute>
                            <Home />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/lesson"
                        element={
                          <ProtectedRoute>
                            <Lesson />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/assessment"
                        element={
                          <ProtectedRoute>
                            <Assessment />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/full-score"
                        element={
                          <ProtectedRoute>
                            <FullScorePage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/score"
                        element={
                          <ProtectedRoute>
                            <Score />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/naming-letter-score"
                        element={
                          <ProtectedRoute>
                            <NamingLetterScore />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/naming-letter-correspondence-score"
                        element={
                          <ProtectedRoute>
                            <NamingLetterCorrespondenceScore />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/naming-letters"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={masteryLessonMockLetterNamingFluency}
                            >
                              <MasteryLesson
                                data={masteryLessonMockLetterNamingFluency}
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/naming-letters-correspondence"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={
                                masteryLessonMockLetterNamingCorrespondenceFluency
                              }
                            >
                              <MasteryLesson
                                data={
                                  masteryLessonMockLetterNamingCorrespondenceFluency
                                }
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/default"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={masteryLessonMockDefault}
                            >
                              <MasteryLesson data={masteryLessonMockDefault} />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/letter-sound-fluency"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={masteryLessonMockLetterSoundFluency}
                            >
                              <MasteryLesson
                                data={masteryLessonMockLetterSoundFluency}
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/letter-sound-completions-fluency"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={
                                masteryLessonMockLetterSoundCompletionsFluency
                              }
                            >
                              <MasteryLesson
                                data={
                                  masteryLessonMockLetterSoundCompletionsFluency
                                }
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/letter-sound-fluency-alphabet"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={masteryLessonMockLetterSoundFluencyAlphabet}
                            >
                              <MasteryLesson
                                data={
                                  masteryLessonMockLetterSoundFluencyAlphabet
                                }
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/mastery/first-sound-fluency"
                        element={
                          <ProtectedRoute>
                            <MasteryLessonProvider
                              data={masteryLessonMockFirstSoundFluency}
                            >
                              <MasteryLesson
                                data={masteryLessonMockFirstSoundFluency}
                              />
                            </MasteryLessonProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/letter-sound-score"
                        element={
                          <ProtectedRoute>
                            <LetterSoundFluencyScore />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/first-sound-score"
                        element={
                          <ProtectedRoute>
                            <FirstSoundFluencyScore />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </AudioProvider>
                </ModalProvider>
              </InterventionsProvider>
            </SpeechProvider>
          </AvatarProvider>
        </LessonProvider>
      </AuthProvider>
    </AppConfigProvider>
  );
}

export default App;
