import { Avatar, useAvatar } from "alpha-ai-avatar-sdk-react";
import alice from "../../assets/alice.png";
import aliceSquare from "../../assets/alice-square.jpeg";

export const RichAvatar: React.FC<{
  message: string;
  useMouthZoom?: boolean;
  size?: string;
  freeze?: boolean;
  onCamera?: boolean;
  avatarState?: string;
  extraStyle?: React.CSSProperties;
}> = ({
  useMouthZoom = false,
  size = "160",
  freeze,
  onCamera,
  avatarState,
  extraStyle,
}) => {
  const { stop, isConnected, isAvatarSpeaking } = useAvatar();

  function handleSay() {
    if (isAvatarSpeaking) {
      return stop();
    }
    // Feature removed for now, they don't want the click making the avatar speaking
    // say(message, { prosody: { rate: "-10%" } });
  }
  const sizeInPixels = `${size}px`;
  const avatarStyle = useMouthZoom
    ? { height: "450px", width: "450px", minWidth: "450px", minHeight: "450px" }
    : {
        height: sizeInPixels,
        width: sizeInPixels,
        minWidth: "120px",
        minHeight: "120px",
      };

  const className = `relative overflow-hidden`;

  const style = {
    borderRadius: onCamera ? "21px" : "50%",
    width: sizeInPixels,
    height: sizeInPixels,
    minHeight: "120px",
    minWidth: "120px",
    ...extraStyle,
  };

  return (
    <div style={style} className={className}>
      <img
        style={style}
        className={`absolute z-${freeze ? "50" : "0"}`}
        src={onCamera ? aliceSquare : alice}
      />
      {isConnected && !freeze && (
        <div
          className={`flex flex-col gap-4 items-center absolute z-10 ${
            useMouthZoom ? "-top-[150%] -left-[93%]" : ""
          }`}
          style={avatarStyle}
        >
          <Avatar style={avatarStyle} onClick={handleSay} />

          {avatarState && (
            <div className="flex w-full justify-center items-center bg-black bg-opacity-50 text-white  text-lg absolute bottom-0 left-0">
              <span className="text-center ml-2">{avatarState}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
