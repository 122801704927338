import { createContext, useContext, useState } from "react";
import { useTimer } from "../../hooks/useTimer";

const MasteryLessonContext = createContext<any | undefined>(undefined);

export const MasteryLessonProvider: React.FC<any> = ({ children, data }) => {
  if (!data) return null;
  const timer = useTimer();
  // Data will come from backend logic in the future
  const { exercise, activity } = data.current;
  const currentExerciseIndex = exercise;
  const currentActivityIndex = activity;

  // State to keep track of the current exercise and activity
  const [currentExercise, setCurrentExercise] = useState(currentExerciseIndex);
  const [currentActivity, setCurrentActivity] = useState(currentActivityIndex);
  const [exerciseType, setExerciseType] = useState(
    data.exercises[currentExercise].type
  );
  const [activityType, setActivityType] = useState(
    data.exercises[currentExercise].activities[currentActivity].type
  );
  const [avatarState, setAvatarState] = useState<string>("");
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const [resultsJson, setResultsJson] = useState<any>(null);

  function next() {
    if (
      currentActivity <
      data.exercises[currentExercise].activities.length - 1
    ) {
      console.log("next activity");
      setCurrentActivity(currentActivity + 1);
      setActivityType(
        data.exercises[currentExercise].activities[currentActivity + 1].type
      );
    } else if (currentExercise < data.exercises.length - 1) {
      console.log("next exercise");
      setCurrentExercise(currentExercise + 1);
      setCurrentActivity(0);
      setActivityType(data.exercises[currentExercise + 1].activities[0].type);
      setExerciseType(data.exercises[currentExercise + 1].type);
    } else {
      console.log("lesson completed");
      setLessonCompleted(true);
    }
  }

  return (
    <MasteryLessonContext.Provider
      value={{
        currentExercise,
        setCurrentExercise,
        currentActivity,
        setCurrentActivity,
        exerciseType,
        setExerciseType,
        activityType,
        setActivityType,
        next,
        avatarState,
        setAvatarState,
        lessonCompleted,
        setLessonCompleted,
        resultsJson,
        setResultsJson,
        data,
        timer,
      }}
    >
      {children}
    </MasteryLessonContext.Provider>
  );
};

export const useMasteryLesson = () => {
  const context = useContext(MasteryLessonContext);
  if (context === undefined) {
    throw new Error(
      "useMasteryLesson must be used within an MasteryLessonProvider"
    );
  }
  return context;
};
