interface FrequentlyConfusedLettersProps {
  letters: string[];
}

function FrequentlyConfusedLetters({
  letters,
}: FrequentlyConfusedLettersProps) {
  return (
    <div className="text-white text-2xl">
      <h1 className="text-3xl mb-4 font-bold">Frequently Confused Letters</h1>
      <div className="w-[500px] flex flex-wrap justify-center items-center">
        {letters.map((letter, index) => {
          return (
            <div
              className="flex justify-center items-center"
              key={`frequently-confused-${index}-${letter}`}
            >
              <div className="w-20 h-20 mr-1 mb-2 flex flex-col bg-purple-faded rounded-3xl p-2 flex items-center justify-center">
                <div className="">{letter?.toUpperCase()}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FrequentlyConfusedLetters;
