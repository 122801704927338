import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

type Config = {
  pronunciationScoreThreshold: number | undefined;
  unexpectedBreakThreshold: number | undefined;
  useAzureMispronunciationFlag: boolean;
  openaiRealtimeConfig: {
    temperature: string | number;
  };
};

type ConfigContextType = {
  configs: Config[];
  currentConfigIndex: number;
  switchConfig: (index: number) => void;
  saveConfig: ({ config, index }: { config: Config; index: number }) => void;
  currentConfig: Config;
};

const CONFIGS_STORAGE_KEY = "appConfigs";
const CURRENT_CONFIG_INDEX_KEY = "currentConfigIndex";

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

export const AppConfigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const defaultConfig: Config = {
    pronunciationScoreThreshold: 75,
    unexpectedBreakThreshold: 3,
    useAzureMispronunciationFlag: true,
    openaiRealtimeConfig: {
      temperature: 0.8,
    },
  };

  const storedConfigs = localStorage.getItem(CONFIGS_STORAGE_KEY);
  const defaultConfigs: Config[] = storedConfigs
    ? JSON.parse(storedConfigs)
    : Array(5).fill(defaultConfig);

  const storedCurrentConfigIndex = localStorage.getItem(
    CURRENT_CONFIG_INDEX_KEY
  );
  const defaultCurrentConfigIndex = storedCurrentConfigIndex
    ? parseInt(storedCurrentConfigIndex, 10)
    : 0;

  const [configs, setConfigs] = useState<Config[]>(defaultConfigs);
  const [currentConfigIndex, setCurrentConfigIndex] = useState(
    defaultCurrentConfigIndex
  );
  const [currentConfig, setCurrentConfig] = useState<Config>(
    configs[currentConfigIndex]
  );

  useEffect(() => {
    setCurrentConfig(configs[currentConfigIndex]);
  }, [currentConfigIndex, configs]);

  useEffect(() => {
    localStorage.setItem(CONFIGS_STORAGE_KEY, JSON.stringify(configs));
  }, [configs]);

  useEffect(() => {
    localStorage.setItem(
      CURRENT_CONFIG_INDEX_KEY,
      currentConfigIndex.toString()
    );
  }, [currentConfigIndex]);

  const switchConfig = (index: number) => {
    if (index >= 0 && index < configs.length) {
      setCurrentConfigIndex(index);
    }
  };

  const saveConfig = ({ config, index }: { config: Config; index: number }) => {
    if (index >= 0 && index < configs.length) {
      const newConfigs = [...configs];
      newConfigs[index] = config;
      setConfigs(newConfigs);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        configs,
        currentConfigIndex,
        saveConfig,
        switchConfig,
        currentConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useAppConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
