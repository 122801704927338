import {
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE,
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY,
} from "../utils/constants";

type ExerciseType =
  | "COLUMNS_EXERCISE"
  | "PICTURES_EXERCISE"
  | "LETTER_SOUND_FLUENCY_EXERCISE"
  | "LETTER_NAMING_FLUENCY_EXERCISE"
  | "LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE";
type ActivityType =
  | "REPEAT_AFTER_TEACHER_ACTIVITY"
  | "POINT_AND_SAY_ACTIVITY"
  | "SPELL_WORD_ACTIVITY"
  | "ANSWER_THE_QUESTION_ACTIVITY"
  | "TILT_HEAD_ACTIVITY"
  | "DETECT_PHONEMES"
  | "LETTER_SOUND_FLUENCY_ACTIVITY"
  | "LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY";

interface Activity {
  id: number;
  type: ActivityType;
  props: Record<string, any>;
}

interface Exercise {
  id: number;
  type: ExerciseType;
  props: Record<string, any>;
  activities: Activity[];
}

interface Lesson {
  id: number;
  current: {
    exercise: number;
    activity: number;
  };
  exercises: Exercise[];
}

const data: Lesson = {
  id: 281829,
  current: {
    exercise: 0,
    activity: 0,
  },
  exercises: [
    {
      id: 781231827,
      type: LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE,
      props: {
        title: "Letter Naming Correspondence",
        completeReferenceText:
          "a b c d e f g h i j k l m n o p q r s t u v w x y z",
      },
      activities: [
        {
          id: 8123541,
          type: LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY,
          props: {
            letters: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],
          },
        },
        {
          id: 81235412,
          type: LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY,
          props: {
            letters: ["k", "l", "m", "n", "o", "p", "q", "r", "s", "t"],
          },
        },
        {
          id: 8123541211,
          type: LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY,
          props: {
            letters: ["u", "v", "w", "x", "y", "z"],
          },
        },
      ],
    },
  ],
};

export default data;
