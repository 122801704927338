import React, { createContext, useContext, useState, ReactNode } from "react";
import { Emotions, HandPoses, HeadPoses } from "../../types/gestures";

interface GestureDetectionContextType {
  headPose: string;
  setHeadPose: (pose: HeadPoses) => void;
  handPose: string;
  setHandPose: (pose: HandPoses) => void;
  emotion: string;
  setEmotion: (emotion: Emotions) => void;
  detectionActive: boolean;
  setDetectionActive: (active: boolean) => void;
}

const GestureDetectionContext = createContext<
  GestureDetectionContextType | undefined
>(undefined);

export const GestureDetectionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [headPose, setHeadPose] = useState<HeadPoses>(HeadPoses.NEUTRAL);
  const [handPose, setHandPose] = useState<HandPoses>(HandPoses.NONE);
  const [emotion, setEmotion] = useState<Emotions>(Emotions.NEUTRAL);
  const [detectionActive, setDetectionActive] = useState(false);

  return (
    <GestureDetectionContext.Provider
      value={{
        headPose,
        setHeadPose,
        handPose,
        setHandPose,
        emotion,
        setEmotion,
        detectionActive,
        setDetectionActive,
      }}
    >
      {children}
    </GestureDetectionContext.Provider>
  );
};

export const useGestureDetection = () => {
  const context = useContext(GestureDetectionContext);
  if (!context) {
    throw new Error("useGestureDetection must be used within a ConfigProvider");
  }
  return context;
};
