import lodash from "lodash";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { cleanString } from "./string";

export class SpeechAnalysis {
  constructor({
    referenceText,
    speechToken,
    onRecognizing,
    onRecognized,
    onCancelled,
    onSessionStopped,
  }: any) {
    const self = this;

    self.referenceText = cleanString(referenceText);

    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      speechToken.token,
      speechToken.region
    );

    speechConfig.speechRecognitionLanguage =
      localStorage.getItem("user-locale") ?? "en-US";
    this.speechConfig = speechConfig;

    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

    const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
      cleanString(referenceText),
      sdk.PronunciationAssessmentGradingSystem.HundredMark,
      sdk.PronunciationAssessmentGranularity.Phoneme,
      true
    );

    pronunciationAssessmentConfig.enableProsodyAssessment = true;

    // pronunciationAssessmentConfig.phonemeAlphabet = "IPA";
    pronunciationAssessmentConfig.phonemeAlphabet = "sapi";

    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    pronunciationAssessmentConfig.applyTo(recognizer);

    recognizer.sessionStopped = function () {
      recognizer.stopContinuousRecognitionAsync();
      recognizer.close();

      // self.calculateOverallPronunciationScore();
      onSessionStopped();
    };

    recognizer.canceled = function (_, e) {
      if (e.reason === sdk.CancellationReason.Error) {
        const str =
          "(cancel) Reason: " +
          sdk.CancellationReason[e.reason] +
          ": " +
          e.errorDetails;
        console.log("Recognizer canceled", str);
      }
      recognizer.stopContinuousRecognitionAsync();
      onCancelled();
    };

    recognizer.recognizing = (_, e: any) => {
      onRecognizing(e);
      console.log("API recognizing results", e.result.text);
      self.recognizingText = e.result.text;
      self.recognizedWordsBeforeAnalysis = e.result.text
        .split(" ")
        .toLowerCase();
    };

    recognizer.recognized = (_, e) => {
      self.jo = JSON.parse(
        e.result.properties.getProperty(
          sdk.PropertyId.SpeechServiceResponse_JsonResult
        )
      );

      const nb = self.jo["NBest"][0];
      console.log("API recognized results", nb);
      self.pronunciationAssessment = nb.PronunciationAssessment;
      self.startOffset = nb.Words[0].Offset;
      const localtext = lodash.map(nb.Words, (item) => item.Word.toLowerCase());
      self.currentText = self.currentText.concat(localtext);
      self.fluencyScores.push(nb.PronunciationAssessment.FluencyScore);
      self.prosodyScores.push(nb.PronunciationAssessment.ProsodyScore);
      const isSucceeded = self.jo.RecognitionStatus === "Success";
      const nBestWords = self.jo.NBest[0].Words;
      self.currentSpeechResult = nBestWords;

      const durationList: any = [];
      lodash.forEach(nBestWords, (word) => {
        if (!self) return;
        self.recognizedWords.push(word);
        durationList.push(word.Duration);
      });
      self.durations.push(lodash.sum(durationList));

      if (isSucceeded && nBestWords) {
        self.allWords.push(...nBestWords);
      }
      self.lastWords = nBestWords;
      // self.calculateOverallPronunciationScore();
      onRecognized(e);
    };

    self.recognizer = recognizer;
  }

  speechConfig: any;
  pronunciationAssessment: any;
  recognizer: any;
  recognizedWordsBeforeAnalysis: any = [];
  recognizingText: string = "";
  scoreNumber: any = {
    accuracyScore: 0,
    fluencyScore: 0,
    compScore: 0,
    prosodyScore: 0,
  };
  allWords: any = [];
  currentText: any = [];
  startOffset = 0;
  recognizedWords: any = [];
  fluencyScores: any = [];
  prosodyScores: any = [];
  durations: any = [];
  jo: any = {};
  language = "en-AU";
  lastWords: any = [];
  lastWord: string = "";
  currentSpeechResult: any = [];
  referenceText = "";

  updateSpeechToken(newSpeechToken: string) {
    this.speechConfig.setProperty(
      sdk.PropertyId.SpeechServiceAuthorization_Token,
      newSpeechToken
    );
  }

  reset() {
    this.scoreNumber = {
      accuracyScore: 0,
      fluencyScore: 0,
      compScore: 0,
      prosodyScore: 0,
    };
    this.allWords = [];
    this.currentText = [];
    this.startOffset = 0;
    this.recognizedWords = [];
    this.fluencyScores = [];
    this.prosodyScores = [];
    this.durations = [];
    this.jo = {};
    this.lastWords = [];
    this.lastWord = "";
  }

  close() {
    console.log("Closed mic");
    if (!this.recognizer) return;
    this.recognizer.stopContinuousRecognitionAsync(() => {
      this.recognizer?.close();
      this.recognizer = null;
    });
  }
}
