import dayjs from "dayjs";
import { useState } from "react";

export const useTimer = () => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const startTimer = () => {
    setStartTime(new Date());
    setEndTime(null); // Reset endTime in case the timer is restarted
  };

  const stopTimer = () => {
    const currentEndTime = new Date();
    setEndTime(currentEndTime);

    // Calculate duration immediately
    const durationInMin = startTime
      ? dayjs(currentEndTime).diff(startTime, "minute", true)
      : null;
    const durationInSec = startTime
      ? dayjs(currentEndTime).diff(startTime, "second")
      : null;

    // Return the durations directly
    return { durationInMin, durationInSec };
  };

  const resetTimer = () => {
    setStartTime(null);
    setEndTime(null);
  };

  return {
    startTimer,
    stopTimer,
    resetTimer,
    // Calculate these only if startTime and endTime are updated in time
    durationInMin:
      startTime && endTime
        ? dayjs(endTime).diff(startTime, "minute", true)
        : null,
    durationInSec:
      startTime && endTime ? dayjs(endTime).diff(startTime, "second") : null,
  };
};
