import { useSpeech } from "../../context/SpeechContext";

const PhraseVisualizer = ({
  paintLastWord,
  lessonPhrase,
  noWordDecoration,
  hoverLocked,
  hoveredLetterIndex,
  onMouseEnter,
  onMouseLeave,
  center,
  width,
  type,
}: any) => {
  const { nextWordIndex, currentPhrase } = useSpeech();

  const phrase = lessonPhrase || currentPhrase;

  const currentPhraseWordsVisualization = phrase
    .split(" ")
    .map((word: string, index: number) => {
      return {
        word,
        isNext: index === nextWordIndex + 1,
        wasSpoken:
          index <= nextWordIndex &&
          (paintLastWord || index !== lessonPhrase.split(" ").length - 1),
        index,
      };
    });

  function wordColor(wordObject: any) {
    if (wordObject.index === hoveredLetterIndex && !wordObject.wasSpoken) {
      return "green";
    }
    if (wordObject.wasSpoken && !noWordDecoration) {
      return "#929292";
    }
    if (wordObject.isNext && hoverLocked) {
      return "#FF0000";
    }
    return "#000000";
  }

  return (
    <div
      className={`${hoverLocked ? "cursor-pointer" : ""} flex flex-col ${
        center ? "items-center" : "items-start"
      }
      ${width ? `w-[${width}px]` : "w-full"}
      justify-center text-semi-black`}
    >
      <div className="flex flex-wrap items-center justify-start">
        {currentPhraseWordsVisualization &&
          currentPhraseWordsVisualization.length > 0 &&
          currentPhraseWordsVisualization.map(
            (wordObject: any, index: number) => {
              return (
                <span
                  style={{
                    color: wordColor(wordObject),
                  }}
                  onMouseEnter={() => {
                    if (hoverLocked && onMouseEnter) {
                      onMouseEnter({ word: wordObject.word, index });
                    }
                  }}
                  onMouseLeave={() => {
                    if (hoverLocked && onMouseLeave) {
                      onMouseLeave({ word: wordObject.word, index });
                    }
                  }}
                  key={wordObject.word + index}
                  className={`
                  ${type === "assessment" ? "text-[26px]" : "text-3xl"}
                  mb-2 font-normal`}
                >
                  {!noWordDecoration && <span>&nbsp;</span>}
                  {wordObject.word}
                </span>
              );
            }
          )}
      </div>
    </div>
  );
};

export default PhraseVisualizer;
