export function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function completeAlphabet(arr: string[]) {
  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

  const uniqueLetters = new Set(arr.map((letter) => letter.toLowerCase()));

  const missingLetters = alphabet.filter(
    (letter) => !uniqueLetters.has(letter)
  );

  return arr.concat(missingLetters);
}

export function removeDuplicates(arr: string[]) {
  return [...new Set(arr)];
}
